import reactReplacePlaceholder from 'react-replace-placeholder';

import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { HotelsIndicativePricesLegalDisclaimerProps } from 'common-types/types/hotels-components/HotelsIndicativePricesLegalDisclaimer';

import STYLES from './HotelsIndicativePricesLegalDisclaimer.module.scss';

const HotelsIndicativePricesLegalDisclaimer = ({
  currentMonth,
  strings,
}: HotelsIndicativePricesLegalDisclaimerProps) => {
  if (!strings || !strings.CityPage_legalDisclaimerIndicativeEstimatedPrices) {
    return null;
  }

  const string = reactReplacePlaceholder(
    strings.CityPage_legalDisclaimerIndicativeEstimatedPrices,
    { current_month: currentMonth },
    '{',
    '}',
  );

  return (
    <div
      className={STYLES.HotelsIndicativePricesLegalDisclaimer}
      data-testid="indicative-prices-legal-disclaimer-section"
    >
      <div className={STYLES.HotelsIndicativePricesLegalDisclaimer__content}>
        <BpkText textStyle={TEXT_STYLES.footnote}>{string}</BpkText>
      </div>
    </div>
  );
};

export default HotelsIndicativePricesLegalDisclaimer;
